import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";
import feedbackImageBackground from "src/images/feedbackImageBackground.jpg";
import { onAllPages } from "src/pages";

const config = [
  {
    id: "feedback",
    name: "Feedback block",
    children: [
      {
        name: "Feedback section heading",
        columnProps: {
          xs: 12,
          sm: 8,
          md: 6,
          className: "text-center pb-4 mt-3",
        },
        moduleComponentName: "SectionHeading",
        moduleProps: {
          alignCenter: true,
          heading: {
            priority: 1,
            text: "Feedback",
            style: { color: "#003f75" },
          },
        },
      },
      {
        name: "Contact us form",
        columnProps: { xs: 12, sm: 8, md: 6 },
        moduleComponentName: "ContactUsForm",
        moduleProps: {
          address: "email@email.com",
          subject: "Contact us demo",
          formClassName: "text-dark font-weight-bold mb-3",
          buttonText: "Submit",
          buttonProps: { variant: "dark" },
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            padding: "10px",
            borderRadius: "5px",
          },
        },
      },
    ],
    rowProps: {
      noGutters: true,
      className:
        "min-vh-100 py-4 py-md-0 d-flex flex-column align-content-center align-items-center justify-content-center",
      style: {
        backgroundImage: `
        linear-gradient(to bottom, rgba(255,255,255,0.7) 0% 100%),
        url(${feedbackImageBackground})
      `,
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
    },
  },
];

const FeedbackPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={[...onAllPages, ...config]} />
    </Container>
  );
};

export default FeedbackPage;
